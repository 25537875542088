<template>
  <a-spin :spinning="loadingPage" size="large">
    <Notification v-if="pageName == 'Overview'" />
    <a-layout>
      <a-layout-sider
        :collapsed="collapsed"
        :trigger="null"
        collapsible
        theme="light"
        width="270"
      >
        <div class="logo">
          <img v-bind:src="logo" />
        </div>

        <div v-if="showGettingStarted" class="getting-started">
          <a-config-provider :theme="getSpecialTheme()">
            <a-button class="w-full" type="primary">
              <template #icon>
                <i class="fa fa-pencil mr-5" />
              </template>
              Getting Started
            </a-button>
          </a-config-provider>
        </div>

        <a-menu
          :selectedKeys="selectedKeys"
          mode="inline"
          :openKeys="openKeys"
          @click="onChangeMenu"
        >
          <a-menu-item key="overview">
            <home-filled />
            <span>Overview</span>
          </a-menu-item>
          <a-sub-menu key="delivery">
            <template #icon>
              <pie-chart-filled />
            </template>
            <template #title>Delivery Monitors</template>
            <a-menu-item key="report">Seed Tests</a-menu-item>
            <a-menu-item key="isp-delivery-report">ISP Trends</a-menu-item>
            <a-menu-item key="gmail-tabs">Gmail Tabs</a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="reputation">
            <template #icon>
              <star-filled />
            </template>
            <template #title>Reputation Monitors</template>
            <a-menu-item key="summary">Summary</a-menu-item>
            <a-menu-item key="rdns-summary">RDNS</a-menu-item>
            <a-menu-item
              v-if="[102, 103, 104].includes(user.service_type)"
              key="microsoft-snds"
            >
              Microsoft SNDS
            </a-menu-item>
            <a-menu-item key="postmaster-reports">
              Google Postmaster Reports
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="dmarc">
            <template #icon>
              <sketch-outlined />
            </template>
            <template #title>DMARC</template>
            <a-menu-item key="dmarc-summary">Summary</a-menu-item>
            <a-menu-item key="record-generator">Generate Record</a-menu-item>
            <a-menu-item key="checker">Checker</a-menu-item>
          </a-sub-menu>
          <a-sub-menu
            v-if="
              user.show_opentracker == 1 ||
              [100, 101].includes(user.service_type)
            "
            key="opentracker"
          >
            <template #icon>
              <MailFilled />
            </template>
            <template #title>Engagement Monitors</template>
            <a-menu-item key="index">Opentracker</a-menu-item>
          </a-sub-menu>
          <a-menu-item
            v-if="disablePreview === null"
            key="email-preview/preview-summary"
          >
            <eye-filled />
            <span>Design Monitors</span>
          </a-menu-item>
          <a-menu-item
            v-if="![100, 101].includes(user.service_type)"
            key="alerts/index"
          >
            <alert-filled />
            <span>Alerts</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>

      <a-layout>
        <Header @handleCollapsed="collapsed = !collapsed" />
        <a-layout-content
          :style="{
            background: '#fff',
            overflowY: 'auto',
          }"
        >
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <div class="footer">{{ copyright }}</div>
  </a-spin>
</template>

<script>
import {
  AlertFilled,
  EyeFilled,
  HomeFilled,
  MailFilled,
  PieChartFilled,
  SketchOutlined,
  StarFilled,
} from '@ant-design/icons-vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import store from '@/store';
import { defaultDomainInfo } from '@/utils/util';

import Header from '../Header/Header.vue';
import Notification from '../Notification/Notification.vue';

export default defineComponent({
  components: {
    AlertFilled,
    EyeFilled,
    HomeFilled,
    PieChartFilled,
    StarFilled,
    SketchOutlined,
    MailFilled,
    Header,
    Notification,
  },
  setup() {
    const router = useRouter();
    const selectedKeys = ref([]);
    const openKeys = ref([]);
    const collapsed = ref(false);
    const user = ref({});
    const currentSetupStep = ref(0);
    const disablePreview = ref(null);
    const logo = ref(defaultDomainInfo.icon_url);
    const copyright = ref(defaultDomainInfo.copyright);

    onMounted(() => {
      user.value = JSON.parse(localStorage.getItem('user'));
      currentSetupStep.value = localStorage.getItem('current_setup_step');
      if (user.value.json_body && user.value.json_body.disablePreview) {
        disablePreview.value = user.value.json_body.disablePreview;
      } else {
        disablePreview.value = null;
      }
      const list = router.currentRoute.value.path.split('/');
      list.shift();
      selectedKeys.value = [...list];
      list.pop();
      openKeys.value = [...list];

      const zendeskScript = document.createElement('script');
      zendeskScript.setAttribute(
        'src',
        'https://static.zdassets.com/ekr/snippet.js?key=26cfe0fe-c88d-41e1-8a28-ec8212c22a7e'
      );
      zendeskScript.setAttribute('id', 'ze-snippet');
      document.head.appendChild(zendeskScript);

      const domainInfo = JSON.parse(localStorage.getItem('domainInfo'));

      if (domainInfo != null) {
        logo.value = domainInfo.icon_url;
        copyright.value = domainInfo.copyright;
      }
    });

    const onChangeMenu = (event) => {
      selectedKeys.value = [event.key];
      router.push({
        path: `/${event.keyPath.join('/')}`,
      });
    };

    const showGettingStarted = computed(
      () =>
        [102, 103, 104, 105].includes(user.value.service_type) &&
        currentSetupStep.value < 4
    );

    return {
      user,
      collapsed,
      selectedKeys,
      openKeys,
      showGettingStarted,
      disablePreview,
      loadingPage: computed(() => store.state.loadingPage),
      pageName: computed(() => router.currentRoute.value.name),
      onChangeMenu,
      copyright,
      logo,
    };
  },
});
</script>

<style lang="less">
@import './PageLayout.less';
</style>
