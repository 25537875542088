<template>
  <a-spin size="large" :spinning="loadingPage">
    <div class="login-container">
      <div class="middle-box loginscreen animated fadeInDown">
        <div class="brand">
          <img v-bind:src="logo" />
        </div>
        <router-view />
        <p class="legal">{{ copyright }}</p>
      </div>
      <div class="login-background"></div>
    </div>
  </a-spin>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { defaultDomainInfo } from '@/utils/util';

export default defineComponent({
  name: 'AccountLayout',
  setup() {
    const store = useStore();
    const logo = ref(defaultDomainInfo.icon_url);
    const copyright = ref(defaultDomainInfo.copyright);

    onMounted(() => {
      const domainInfo = JSON.parse(localStorage.getItem('domainInfo'));

      if (domainInfo != null) {
        logo.value = domainInfo.icon_url;
        copyright.value = domainInfo.copyright;
      }
    });

    return {
      loadingPage: computed(() => store.state.loadingAccountPage),
      copyright,
      logo,
    };
  },
});
</script>

<style lang="less">
@import './AccountLayout.less';
</style>
